import React, { useState } from 'react';
import { faker } from '@faker-js/faker'; // Ensure you have @faker-js/faker installed for generating fake data
import Button from '@mui/material/Button';
import ExportData from './ExportData';

const SyntheticDataGenerator = ({ onGenerate }) => {
  const [prompt, setPrompt] = useState('');
  const [rows, setRows] = useState(10);
  const [includeDemographics, setIncludeDemographics] = useState(false);
  const [generatedData, setGeneratedData] = useState(null);

  const handleGenerateData = async () => {
    const generateRow = (index) => {
      const row = {
        id: index + 1,
        value: `${prompt} ${index + 1}`,
      };
      if (includeDemographics) {
        row.firstname = faker.name.firstName();
        row.lastname = faker.name.lastName();
        row.email = faker.internet.email();
        row.phonenumber = faker.phone.number();
        row.address = faker.address.streetAddress();
      }
      return row;
    };

    const syntheticData = {
      data: `Generated data based on the prompt: "${prompt}" with ${rows} rows`,
      rows: Array.from({ length: rows }, (_, index) => generateRow(index)),
    };

    setGeneratedData(syntheticData);
    if (onGenerate) {
      onGenerate(syntheticData);
    }
  };

  const handleClearData = () => {
    setGeneratedData(null);
    if (onGenerate) {
      onGenerate(null);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Cynthetic Data Generator</h2>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="prompt">What type of data would you like to create?</label>
        <textarea
          id="prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          rows="4"
          cols="50"
          placeholder="Enter your prompt here..."
          style={{ width: '100%', padding: '10px', marginTop: '10px' }}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="rows">Number of Rows:</label>
        <input
          id="rows"
          type="number"
          value={rows}
          onChange={(e) => setRows(e.target.value)}
          style={{ width: '100%', padding: '10px', marginTop: '10px' }}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <input
          id="includeDemographics"
          type="checkbox"
          checked={includeDemographics}
          onChange={(e) => setIncludeDemographics(e.target.checked)}
        />
        <label htmlFor="includeDemographics" style={{ marginLeft: '10px' }}>
          Include Fake Demographic Profiles
        </label>
      </div>
      <Button onClick={handleGenerateData} style={{padding: '10px 20px', marginRight: '10px', border: '1px solid #1976d2' }}>
        Generate Data
      </Button>
      <Button onClick={handleClearData} style={{ padding: '10px 20px', marginRight: '10px', border: '1px solid red', color: 'red' }}>
        Clear Data
      </Button>
      {generatedData && (
        <div style={{ marginTop: '20px' }}>
          <h3>Generated Data:</h3>
          <pre style={{ background: '#f4f4f4', padding: '10px' }}>
            {JSON.stringify(generatedData, null, 2)}
          </pre>
        </div>
      )}
      {generatedData && (<ExportData data={generatedData} />)}
    </div>
    
  );
};

export default SyntheticDataGenerator;
