import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

function GetCategories() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cynthia-api.bacclabs.io/api/formulate/categories', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Categories:</h1>
      <p>Select one of the categories below to determine what type of data you'd like to generate</p>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Select a category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleChange}
          label="Select a category"
          displayEmpty
        >
          <MenuItem value="" disabled>
            {/* <em>Select a category</em> */}
          </MenuItem>
          {data.map((item, index) => (
            <MenuItem key={index} value={item}>
             {item.replace(/_/g, ' ')}
           </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default GetCategories;
