import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LaunchListWidget from './LaunchListWidget';
import BackgroundHeroImage from '../assets/synthetic-data-background.png';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={BackgroundHeroImage}
        alt="Background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '75%%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          maxWidth: '2000px !important',
          zIndex: 1,
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Generate Cynthetic Data&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: '#fff',
              }}
            >
              {/* Additional text can be added here */}
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.primary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Explore our Cynthia data generator for all your data engineering needs. Import your schema, create all types of data, and export it into your own project.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <LaunchListWidget />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
