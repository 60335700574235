import * as React from 'react';
import { useState } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Cynthetic Data

function DataModels() {
  const [rows, setRows] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileReaders = [];

    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result.trim();
        setRows((prevRows) => [...prevRows, createData(index, fileContent)]);
      };

      reader.onerror = (e) => {
        console.error(`Error reading file ${file.name}:`, e);
      };

      reader.readAsText(file);
      fileReaders.push(reader);
    });
  };

  const createData = (id, note) => {
    return { id, note };
  };

const tableStyle = {
  width: '100%',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
};

const thTdStyle = {
  width: '50%',
  textAlign: 'left',
  padding: '8px',
  borderBottom: '1px solid #ddd',
};

const thStyle = {
  ...thTdStyle,
  backgroundColor: '#f2f2f2',
};

return (
  <div>
    <h1>Medicare Discharge Notes</h1>
    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={thStyle}>ID</th>
          <th style={thStyle}>Note</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            <td style={thTdStyle}>{row.id}</td>
            <td style={thTdStyle}>{row.note}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <input type="file" multiple onChange={handleFileChange} />
    
  </div>
  
);
}

export default DataModels;
