import React from 'react';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

const ExportData = ({ data }) => {
  const handleExportJSON = () => {
    const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
    saveAs(blob, 'data.json');
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <h3>Export Data:</h3>
      <Button
        style={{ padding: '10px 20px', marginRight: '10px', border: '1px solid #1976d2' }}
        onClick={handleExportJSON}
        disabled={!data}
      >
        Export as JSON
      </Button>
      <Button
        style={{ padding: '10px 20px', marginRight: '10px', border: '1px solid #1976d2', color: '#1976d2'}}
        onClick={handleExportExcel}
        disabled={!data}
      >
        Export as Excel
      </Button>
      {data && (
        <CSVLink
          data={data.rows}
          filename="data.csv"
          className="btn btn-primary"
          style={{ padding: '12px 20px', textDecoration: 'none', color: 'white', backgroundColor: '#1976d2', borderRadius: '10px' }}
        >
          Export as CSV
        </CSVLink>
      )}
    </div>
  );
};

export default ExportData;
