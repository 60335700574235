import React from 'react';
import { Helmet } from 'react-helmet';

const LaunchListWidget = () => {
  return (
    <>
      <div className="launchlist-widget" data-key-id="amMP0U" data-height="180px" style={{fontFamily: 'Inter, sans-serif !important', zIndex: '1'}}></div>
      <Helmet>
        <script src="https://getlaunchlist.com/js/widget.js" async />
      </Helmet>
    </>
  );
};

export default LaunchListWidget;
