import React, { useState } from 'react';
import { Button, Modal, Box, TextField } from '@mui/material';

function Integrations() {
  const [open, setOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [credentials, setCredentials] = useState({
    host: '',
    port: '',
    username: '',
    password: '',
  });

  const integrations = [
    {
      id: 1,
      icon: 'fas fa-database', // Default icon
      title: 'PostgreSQL',
      description: 'A powerful, open-source object-relational database system.',
    },
    {
      id: 2,
      icon: 'fas fa-server', // Server icon
      title: 'MySQL',
      description: 'The world\'s most popular open-source relational database management system.',
    },
    {
      id: 3,
      icon: 'fas fa-chart-line', // Chart line icon
      title: 'BigQuery',
      description: 'A fully-managed, serverless data warehouse that enables scalable analysis.',
    },
    {
      id: 4,
      icon: 'fas fa-cubes', // Cubes icon
      title: 'Redshift',
      description: 'A fast, scalable data warehouse that makes it simple and cost-effective to analyze data.',
    },
    {
      id: 5,
      icon: 'fas fa-database', // Default icon for MongoDB
      title: 'MongoDB',
      description: 'A source-available cross-platform document-oriented database program.',
    },
  ];

  const handleOpen = (integration) => {
    setSelectedIntegration(integration);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedIntegration(null);
    setCredentials({
      host: '',
      port: '',
      username: '',
      password: '',
    });
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log('Submitted credentials for', selectedIntegration.title, credentials);
    handleClose();
  };

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  const cardStyle = {
    flex: '0 1 calc(33% - 20px)',
    boxSizing: 'border-box',
    padding: '20px',
    margin: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: '#fff',
  };

  const iconStyle = {
    fontSize: '2em',
    marginBottom: '10px',
  };

  const titleStyle = {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const descriptionStyle = {
    fontSize: '1em',
    color: '#555',
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <h1>Select your Input Data Source</h1>
      <p>To help us better match your real data choose from one of the following data sources to connect to</p>
      <div style={containerStyle}>
        {integrations.map((integration) => (
          <Button
            key={integration.id}
            variant="outlined"
            style={cardStyle}
            onClick={() => handleOpen(integration)}
          >
            <div>
              <i className={integration.icon} style={iconStyle}></i>
              <div style={titleStyle}>{integration.title}</div>
              <div style={descriptionStyle}>{integration.description}</div>
            </div>
          </Button>
        ))}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <h2 id="modal-title">Enter Credentials for {selectedIntegration?.title}</h2>
          <TextField
            name="host"
            label="Host"
            value={credentials.host}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="port"
            label="Port"
            value={credentials.port}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="username"
            label="Username"
            value={credentials.username}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            value={credentials.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button onClick={handleSubmit} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Integrations;
